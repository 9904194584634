import { useRouteLoaderData } from "@remix-run/react";
import { type SerializeFrom } from "@remix-run/server-runtime";

import { type loader as rootLoader } from "~/root";

/**
 * @returns the request info from the root loader
 */
export function useRequestInfo() {
  const data = useRouteLoaderData("root") as SerializeFrom<typeof rootLoader>;
  return data.requestInfo;
}
